
  import pageTitle from '@/components/pageTitle.vue';
  import { defineComponent } from 'vue';
  import { getRolesList, getMenuList, addRoles, delRoles, editRoles, getRolesDetail } from '@/axios/brand';
  import { ElMessage,ElMessageBox } from 'element-plus';
  interface Tree {
    name: string,
    id: number
  }
  export default defineComponent({
    name: 'roleManager',
    data(){
      return {
        roleName: '',
        roleDialog: false,
        form: {
          id: 0,
          role_name: '',
          remark: '',
          checkMenu: []
        },
        tableData: [{id:1,name:'',count:0,remark: ''}],
        defaultProps: {
          children: 'child',
          label: 'name',
        },
        defaultCheck: [],
        menuTree: [ {name: '',id:0} ],
        rules: {
          role_name: [
            { required: true, message: '请输入品牌名称', trigger: 'blur' }
          ]
        }
      }
    },
    components: {
      pageTitle
    },
    mounted(){
      this.getRolesLists();
      // let _this = this;
      // getMenuList().then((data:any) => {
      //   data.map((item:any) => {
      //     item.child.map((citem:any)=>{
      //       if(citem.id==2){
      //         citem.disabled = true;
      //       }
      //       citem.child.map((ditem:any) => {
      //         if(ditem.id==3){
      //           ditem.disabled = true;
      //         }
      //       })
      //     })
      //   })
      //   _this.menuTree =  data;
      // }).catch((err:any) => {})
        this.menuTree =  JSON.parse(sessionStorage.getItem('accountInfo') || '').menu_list;
    },
    methods: {
      getRolesLists: function(){
        let _this = this;
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        // --->
        getRolesList({
          brand_pk: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_pk,
          role_name: this.roleName
        }).then((data:any) => {
          console.log(data)
          _this.tableData = data;
        }).catch((err:any) => {
          console.log(err)
        })
      },
      //展示弹窗
      showRole: function(val?:number){
        this.roleDialog = true;
        if(val){
          let _this = this;
          getRolesDetail({
            role_id: val
          }).then((data:any) => {
            console.log(data)
            _this.form = data;
            _this.form.role_name = data.name;
            let allTree = data.menu, newarr:any = [], checksMenu:any = [];
            let reds = (arr:any) => {
              arr.map((item:any) => {
                checksMenu.push(item.id)
                if(item.child.length === 0){
                  newarr.push(item.id);
                }
                else{
                  reds(item.child)
                }
              })
            }
            reds(allTree)
            _this.defaultCheck = newarr;
            _this.form.checkMenu = checksMenu;
          }).catch((err:any) => {
            console.log(err)
          })
        }
      },
      //关闭弹窗
      closeInfo: function(){
        this.roleDialog = false;
        this.form = {
          id: 0,
          role_name: '',
          remark: '',
          checkMenu: []
        },
        this.defaultCheck = [];
      },
      //弹窗确认
      roleClick: function(){
        // if(!this.form.role_name.trim()){
        //   ElMessage('请输入角色名称');
        //   return false;
        // }
        let _this = this;
        //新增
        if(!this.form.id){
          let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
          addRoles({
            brand_pk: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_pk,
            brand_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_id,
            remark: this.form.remark,
            menu_ids: this.form.checkMenu.join(','),
            role_name: this.form.role_name,
          }).then((data:any) => {
            ElMessage('新增角色成功')
            _this.getRolesLists();
            _this.roleDialog = false;
          }).catch((err:any) => {
            console.log(err)
          })
        }
        else{
          editRoles({
            id: this.form.id,
            remark: this.form.remark,
            menu_ids: this.form.checkMenu.join(','),
            role_name: this.form.role_name,
          }).then((data:any) => {
            ElMessage('修改角色成功')
            _this.getRolesLists();
            _this.roleDialog = false;
          }).catch((err:any) => {
            console.log(err)
          })
        }
      },
      //删除角色
      delRole: function(ids: number){
        let _this = this;
        ElMessageBox.confirm('是否删除该角色').then(() => {
          delRoles({
            id: ids,
          }).then((data:any) => {
            ElMessage('删除角色成功')
            _this.getRolesLists();
          }).catch((err:any) => {
            console.log(err)
          })
        }).catch(() => {
          
        })
      },
      //菜单选择
      handleCheckChange: function(data: Tree,checked: boolean,indeterminate: boolean){
        console.log(data,checked,indeterminate)
        let oldarr = JSON.parse(JSON.stringify(this.form.checkMenu));
        if(checked || indeterminate){
          oldarr.push(data.id)
        }
        else{
          this.form.checkMenu.some((item,index) => {
            if(item === data.id){
              oldarr.splice(index,1);
              return true;
            }
          })
        }
        oldarr = Array.from(new Set(oldarr))
        this.form.checkMenu = oldarr;
      }
    }
  });
